import React, { useState, useEffect } from "react";
import { gzipSync } from "fflate";
import axios from 'axios';
import FFT from 'fft.js';
import "./SoundMagic.css";
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';
const FILE_SIZE_WARNING_THRESHOLD = 350;
const MAX_DURATION_SECONDS = 30;
const DEFAULT_SAMPLE_RATE = 44100;

const SoundMagic = () => {
  const [encodedData, setEncodedData] = useState("");
  const [generatedHTML, setGeneratedHTML] = useState("");
  const [previewHTML, setPreviewHTML] = useState("");
  const [includeBackground, setIncludeBackground] = useState(false);
  const [inscriptionId, setInscriptionId] = useState("");
  const [downsampleFactor, setDownsampleFactor] = useState(4);
  const [quantizationBits, setQuantizationBits] = useState(5);
  const [scaleFactor, setScaleFactor] = useState(31);
  const [fileSize, setFileSize] = useState({
    original: 0,
    compressed: 0,
    compressionRatio: 0
  });
  const [orderDetails, setOrderDetails] = useState({
    totalAmount: 0,
    totalAmountBTC: '',
    payAddress: '',
    receiverAddress: '',
    devAddress: '',
    devFee: 0,
    feeRate: null,
  });
  const [recommendedFees, setRecommendedFees] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const MAX_DURATION_SECONDS = 30;
  const pakoUrl = "/content/fba6f95fb1152db43304a27dce8cb8c65509eba6ab0b6958cedeb33e5f443077i0";
  const [noiseGateThreshold, setNoiseGateThreshold] = useState(-60);
  const [compressorThreshold, setCompressorThreshold] = useState(-24);
  const [compressorRatio, setCompressorRatio] = useState(4);
  const [highCutFreq, setHighCutFreq] = useState(16000);
  const [lowCutFreq, setLowCutFreq] = useState(20);
  const [noiseReduction, setNoiseReduction] = useState(0.5);

  const [audioContext] = useState(() => new (window.AudioContext || window.webkitAudioContext)());
  const [sampleRate, setSampleRate] = useState(DEFAULT_SAMPLE_RATE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rawFileSize, setRawFileSize] = useState(0);
  const [currentAudioBuffer, setCurrentAudioBuffer] = useState(null);
  const [waveformData, setWaveformData] = useState([]);
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(10);
  const [isDragging, setIsDragging] = useState(false);
  const [dragHandle, setDragHandle] = useState(null);

  useEffect(() => {
    const fetchRecommendedFees = async () => {
      try {
        const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
        setRecommendedFees(response.data);
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          feeRate: response.data.fastestFee
        }));
      } catch (error) {
        console.error('Error fetching recommended fees:', error);
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          feeRate: 10
        }));
      }
    };
    fetchRecommendedFees();
  }, []);

  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const size = file.size;
    setRawFileSize(size);
    
    try {
      const arrayBuffer = await file.arrayBuffer();
      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        try {
          // Create a new buffer limited to 30 seconds
          const limitedBuffer = audioContext.createBuffer(
            1, 
            Math.min(audioBuffer.sampleRate * MAX_DURATION_SECONDS, audioBuffer.length), 
            audioBuffer.sampleRate
          );
          
          // Copy only first 30 seconds of audio
          const sourceData = audioBuffer.getChannelData(0);
          const limitedData = sourceData.slice(0, audioBuffer.sampleRate * MAX_DURATION_SECONDS);
          limitedBuffer.copyToChannel(limitedData, 0);
          
          // Update state with limited buffer
          setSampleRate(limitedBuffer.sampleRate);
          setCurrentAudioBuffer(limitedBuffer);
          setWaveformData(generateWaveformData(limitedBuffer));
          setTrimStart(0);
          setTrimEnd(Math.min(limitedBuffer.duration, MAX_DURATION_SECONDS));
          processAudioAndUpdate(limitedBuffer, size);
          setErrorMessage(null);
          
        } catch (error) {
          console.error('Processing error:', error);
          setErrorMessage("Error processing audio. Please try different settings.");
        }
      });
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage("Error processing audio. Please try different settings.");
    }
  };

  const processAudioAndUpdate = (audioBuffer, size) => {
    setEncodedData("");
    setGeneratedHTML("");
    setPreviewHTML("");
    
    try {
      const trimmedBuffer = audioContext.createBuffer(1, 
        Math.floor((trimEnd - trimStart) * audioBuffer.sampleRate), 
        audioBuffer.sampleRate
      );
      
      const originalData = audioBuffer.getChannelData(0);
      const startSample = Math.floor(trimStart * audioBuffer.sampleRate);
      const endSample = Math.floor(trimEnd * audioBuffer.sampleRate);
      const trimmedData = originalData.slice(startSample, endSample);
      
      trimmedBuffer.copyToChannel(trimmedData, 0);
      
      const encoded = optimizeAndEncodePCMData(trimmedBuffer);
      setEncodedData(encoded);
      generateHTML(encoded, size);
    } catch (error) {
      console.error('Processing error:', error);
      setErrorMessage("Error processing audio. Please try different settings.");
    }
  };

  const handleTrimChange = (newStart, newEnd) => {
    setTrimStart(newStart);
    setTrimEnd(newEnd);
    if (currentAudioBuffer) {
      const trimmedBuffer = audioContext.createBuffer(1, 
        Math.floor((newEnd - newStart) * currentAudioBuffer.sampleRate), 
        currentAudioBuffer.sampleRate
      );
      
      const originalData = currentAudioBuffer.getChannelData(0);
      const startSample = Math.floor(newStart * currentAudioBuffer.sampleRate);
      const trimmedData = originalData.slice(startSample, 
        startSample + Math.floor((newEnd - newStart) * currentAudioBuffer.sampleRate));
      
      trimmedBuffer.copyToChannel(trimmedData, 0);
      
      const encoded = optimizeAndEncodePCMData(trimmedBuffer);
      setEncodedData(encoded);
      generateHTML(encoded, rawFileSize);
    }
  };

  const truncateAudioBuffer = (audioBuffer, maxDuration, audioContext) => {
    const sampleRate = audioBuffer.sampleRate;
    const maxSamples = maxDuration * sampleRate;
    const truncatedData = audioBuffer.getChannelData(0).slice(0, maxSamples);
    const truncatedAudioBuffer = audioContext.createBuffer(1, truncatedData.length, sampleRate);
    truncatedAudioBuffer.copyToChannel(truncatedData, 0);
    return truncatedAudioBuffer;
  };

  const optimizeAndEncodePCMData = (audioBuffer) => {
    const downsampledData = downsampleWithFilter(audioBuffer.getChannelData(0), downsampleFactor);
    const normalizedData = normalizeAudio(downsampledData);
    const quantizedData = quantize(normalizedData, quantizationBits);
    const binaryData = Uint8Array.from(quantizedData);
    const compressedData = gzipSync(binaryData);
    return btoa(String.fromCharCode(...compressedData));
  };

  const downsampleWithFilter = (data, factor) => {
    const filteredData = [];
    for (let i = 0; i < data.length; i += factor) {
      let sum = 0;
      for (let j = 0; j < factor && i + j < data.length; j++) {
        sum += data[i + j];
      }
      filteredData.push(sum / factor);
    }
    return filteredData;
  };

  const normalizeAudio = (data) => {
    let maxAmp = 0;
    for (let i = 0; i < data.length; i++) {
      maxAmp = Math.max(maxAmp, Math.abs(data[i]));
    }
    const normalized = new Array(data.length);
    for (let i = 0; i < data.length; i++) {
      normalized[i] = data[i] / (maxAmp || 1);
    }
    return normalized;
  };

  const quantize = (data, bitDepth) => {
    const maxVal = Math.pow(2, bitDepth) - 1;
    const halfMax = maxVal / 2;
    return data.map(value => {
      const scaled = Math.round((value + 1) * halfMax);
      return Math.max(0, Math.min(scaled, maxVal));
    });
  };

  const calculateFileSize = (htmlContent, originalSize) => {
    const encoder = new TextEncoder();
    const finalSize = encoder.encode(htmlContent).length;
    
    // Convert to KB
    const finalSizeKB = finalSize / 1024;
    const originalSizeKB = originalSize / 1024;
    
    // Calculate compression ratio
    let ratio = 0;
    if (originalSizeKB > 0) {
        ratio = ((originalSizeKB - finalSizeKB) / originalSizeKB * 100).toFixed(1);
    }

    console.log('Original size (KB):', originalSizeKB);
    console.log('Final size (KB):', finalSizeKB);
    console.log('Compression ratio:', ratio);

    return {
        original: originalSizeKB.toFixed(2),
        compressed: finalSizeKB.toFixed(2),
        compressionRatio: ratio
    };
  };

  const generatePreviewHTML = (htmlContent) => {
    if (!htmlContent) return '';
    return htmlContent
      .replace(/['"]\/content\/(.*?)['"](?![^<]*<\/script>)/g, '"https://ordin-delta.vercel.app/content/$1"')
      .replace(
        `"${pakoUrl}"`, 
        `"https://ordin-delta.vercel.app/content/fba6f95fb1152db43304a27dce8cb8c65509eba6ab0b6958cedeb33e5f443077i0"`
      );
  };

  const minifyHTML = (html) => {
    return html
      // Remove comments
      .replace(/<!--(?!>)[\S\s]*?-->/g, '')
      // Remove whitespace between tags
      .replace(/>\s+</g, '><')
      // Remove whitespace at the start and end of lines
      .replace(/^\s+|\s+$/gm, '')
      // Minify CSS while preserving necessary whitespace in rules
      .replace(/<style>([\s\S]*?)<\/style>/gi, function(match, style) {
        return '<style>' + 
          style
            .replace(/\s+/g, ' ')
            .replace(/:\s+/g, ':')
            .replace(/;\s+/g, ';')
            .replace(/,\s+/g, ',')
            .replace(/{\s+/g, '{')
            .replace(/}\s+/g, '}')
            .replace(/\s+}/g, '}')
            .replace(/\s+{/g, '{')
            .trim() +
          '</style>';
      })
      // Minify JavaScript while preserving functionality
      .replace(/<script>([\s\S]*?)<\/script>/gi, function(match, script) {
        return '<script>' + 
          script
            .replace(/\s+/g, ' ')
            .replace(/;\s+/g, ';')
            .replace(/{\s+/g, '{')
            .replace(/}\s+/g, '}')
            .replace(/\s+}/g, '}')
            .replace(/\s+{/g, '{')
            .replace(/,\s+/g, ',')
            .trim() +
          '</script>';
      });
  };

  const generateHTML = (encoded = encodedData, originalSize = 0) => {
    if (!encoded) return;
    
    setFileSize({
      original: 0,
      compressed: 0,
      compressionRatio: 0
    });

    const baseSampleRate = 44100;
    const playbackSampleRate = Math.max(3000, Math.min(baseSampleRate / downsampleFactor, 768000));

    const htmlContent = `<!DOCTYPE html>
<html>
<head>
  <style>
    body{margin:0;overflow:hidden;background:#000}
    iframe{width:100%;height:100%;border:none;position:absolute;top:0;left:0}
    .play-button{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:10;padding:16px 32px;background:rgba(0,0,0,.75);color:#fff;border:none;border-radius:9999px;cursor:pointer;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif;font-size:18px;font-weight:500;letter-spacing:.025em;transition:all .2s ease;backdrop-filter:blur(8px);box-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);display:flex;align-items:center;gap:8px}
    .play-button:hover{background:rgba(0,0,0,.9);transform:translate(-50%,-50%) scale(1.05)}
    .play-button.playing{padding:16px;background:rgba(0,0,0,.5);transform:translate(0,0);top:20px;left:20px}
    .play-button.playing:hover{background:rgba(0,0,0,.7);transform:translate(0,0) scale(1.05)}
  </style>
  <script>let pakoModule;const loadPako=async()=>{if(!pakoModule){pakoModule=await import("${pakoUrl}")}return pakoModule}</script>
</head>
<body>
  ${includeBackground && inscriptionId ? `<iframe id="generativeArt" src="/content/${inscriptionId}" style="position:fixed;top:0;left:0;width:100%;height:100%;border:none;pointer-events:none"></iframe>` : ''}
  <button class="play-button" id="playButton">
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="width:24px;height:24px">
      <path d="M8 5v14l11-7z" fill="currentColor"/>
    </svg>
    <span>Play Audio</span>
  </button>
  <script>
    const encodedData="${encoded}",button=document.getElementById("playButton");let isPlaying=false,audioContext=null,source=null;const decodePCMData=async()=>{const pako=await loadPako(),compressed=atob(encodedData).split("").map(c=>c.charCodeAt(0)),decompressed=pako.inflate(new Uint8Array(compressed)),channelData=Float32Array.from(decompressed,v=>v/${scaleFactor}-1);return channelData},playAudioLoop=async()=>{if(!isPlaying){const channelData=await decodePCMData();audioContext=new(window.AudioContext||window.webkitAudioContext);const audioBuffer=audioContext.createBuffer(1,channelData.length,${playbackSampleRate});audioBuffer.copyToChannel(channelData,0);source=audioContext.createBufferSource();source.buffer=audioBuffer;source.loop=true;source.connect(audioContext.destination);source.start();button.innerHTML='<svg viewBox="0 0 24 24" fill="none" style="width:24px;height:24px"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" fill="currentColor"/></svg>';button.classList.add("playing");isPlaying=true}else{audioContext&&(audioContext.close(),audioContext=null);source&&(source.stop(),source=null);button.innerHTML='<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="width:24px;height:24px"><path d="M8 5v14l11-7z" fill="currentColor"/></svg><span>Play Audio</span>';button.classList.remove("playing");isPlaying=false}};button.addEventListener("click",playAudioLoop)
  </script>
</body>
</html>`;

    const sizes = calculateFileSize(htmlContent, originalSize);
    setFileSize(sizes);
    setShowSizeWarning(parseFloat(sizes.compressed) > FILE_SIZE_WARNING_THRESHOLD);
    setGeneratedHTML(htmlContent);
    setPreviewHTML(generatePreviewHTML(htmlContent));
  };

  const handleDownload = () => {
    if (!generatedHTML) return;
    
    const blob = new Blob([generatedHTML], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sound_magic.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleQuantizationBitsChange = (bits) => {
    setQuantizationBits(bits);
    setScaleFactor(Math.pow(2, bits) - 1);
    
    if (currentAudioBuffer) {
      processAudioAndUpdate(currentAudioBuffer, rawFileSize);
    }
  };

  const copyHTML = async () => {
    try {
      await navigator.clipboard.writeText(generatedHTML);
      alert('HTML copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy HTML:', err);
    }
  };

  const handleReceiverAddressChange = (event) => {
    setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
  };

  const handleFeeRateChange = (event) => {
    setOrderDetails({ ...orderDetails, feeRate: event.target.value });
  };

  const handleInscribe = async () => {
    if (!generatedHTML) {
        alert('Please generate the HTML first.');
        return;
    }

    try {
        // Create a Blob from the HTML content
        const blob = new Blob([generatedHTML], { type: 'text/html' });
        const formData = new FormData();
        formData.append('file', blob, 'sound_magic.html');

        const response = await fetch(`${BACKEND_URL}/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'User-Selected-Fee-Rate': orderDetails.feeRate,
                'User-Selected-Receiver-Address': orderDetails.receiverAddress,
            },
        });

        if (response.ok) {
            const result = await response.json();
            setOrderDetails({
                ...orderDetails,
                totalAmount: result.payAddressAmount,
                totalAmountBTC: result.payAddressAmount / 100000000,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                devAddress: result.devAddress,
                devFee: result.devFee,
                feeRate: result.feeRate,
            });
            setIsModalOpen(true);
        } else {
            throw new Error('Failed to upload HTML and create order');
        }
    } catch (error) {
        console.error('Error during the fetch request:', error);
        alert('Failed to process inscription. Please try again.');
    }
  };

  const handleConfirm = async () => {
    if (window.unisat && window.unisat.sendBitcoin) {
      try {
        const txidPay = await window.unisat.sendBitcoin(
          orderDetails.payAddress, 
          orderDetails.totalAmount, 
          { feeRate: parseInt(orderDetails.feeRate) }
        );
        alert('Transaction successful! TXID: ' + txidPay);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Transaction failed:', error);
        alert('Transaction failed. Please try again or check your wallet.');
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const processWithFFT = (audioData) => {
    const fftSize = 2048;
    const fft = new FFT(fftSize);
    const hannWindow = new Float64Array(fftSize);
    const processedChunks = [];
    const overlapBuffer = new Float32Array(fftSize).fill(0);
    const hopSize = fftSize / 4;
    
    // Create Hann window
    for (let i = 0; i < fftSize; i++) {
      hannWindow[i] = 0.5 * (1 - Math.cos(2 * Math.PI * i / (fftSize - 1)));
    }

    // Noise profile estimation (first pass)
    const noiseProfile = new Float32Array(fftSize / 2);
    let frameCount = 0;
    
    // First pass - estimate noise floor
    for (let i = 0; i < audioData.length; i += fftSize) {
      const chunk = audioData.slice(i, i + fftSize);
      if (chunk.length === fftSize) {
        const input = new Float64Array(fftSize);
        for (let j = 0; j < fftSize; j++) {
          input[j] = chunk[j] * hannWindow[j];
        }

        const spectrum = fft.createComplexArray();
        fft.realTransform(spectrum, input);

        // Calculate magnitude spectrum
        for (let j = 0; j < fftSize / 2; j++) {
          const real = spectrum[j * 2];
          const imag = spectrum[j * 2 + 1];
          const magnitude = Math.sqrt(real * real + imag * imag);

          // Update noise profile
          noiseProfile[j] += magnitude;
        }

        frameCount++;
      }
    }

    // Average noise profile
    for (let i = 0; i < fftSize / 2; i++) {
      noiseProfile[i] /= frameCount;
    }

    // Second pass - apply noise reduction and processing
    for (let i = 0; i < audioData.length; i += hopSize) {
      const chunk = audioData.slice(i, i + fftSize);
      if (chunk.length === fftSize) {
        const input = new Float64Array(fftSize);
        for (let j = 0; j < fftSize; j++) {
          input[j] = chunk[j] * hannWindow[j];
        }

        const spectrum = fft.createComplexArray();
        fft.realTransform(spectrum, input);

        // Apply noise reduction and processing
        for (let j = 0; j < fftSize / 2; j++) {
          const real = spectrum[j * 2];
          const imag = spectrum[j * 2 + 1];
          const magnitude = Math.sqrt(real * real + imag * imag);

          // Apply noise reduction
          const reducedMagnitude = Math.max(magnitude - noiseProfile[j] * noiseReduction, 0);

          // Apply high-cut filter
          if (j * sampleRate / fftSize > highCutFreq) {
            reducedMagnitude = 0;
          }

          // Apply low-cut filter
          if (j * sampleRate / fftSize < lowCutFreq) {
            reducedMagnitude = 0;
          }

          // Update spectrum with processed magnitude
          const phase = Math.atan2(imag, real);
          spectrum[j * 2] = reducedMagnitude * Math.cos(phase);
          spectrum[j * 2 + 1] = reducedMagnitude * Math.sin(phase);
        }

        // Inverse FFT
        const output = fft.createComplexArray();
        fft.inverseTransform(output, spectrum);

        // Overlap-add and store processed chunk
        for (let j = 0; j < fftSize; j++) {
          overlapBuffer[j] += output[j] * hannWindow[j];
        }

        // Store processed chunk and reset overlap buffer
        if (i + hopSize < audioData.length) {
          processedChunks.push(...overlapBuffer.slice(0, hopSize));
          overlapBuffer.set(overlapBuffer.slice(hopSize));
          overlapBuffer.fill(0, hopSize);
        }
      }
    }

    // Concatenate processed chunks and return
    return new Float32Array([...processedChunks, ...overlapBuffer]);
  };

  const generateWaveformData = (audioBuffer) => {
    const channelData = audioBuffer.getChannelData(0);
    const samples = 200; // Number of points in the waveform
    const blockSize = Math.floor(channelData.length / samples);
    const dataPoints = [];

    for (let i = 0; i < samples; i++) {
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum += Math.abs(channelData[(i * blockSize) + j]);
      }
      dataPoints.push(sum / blockSize);
    }

    const maxValue = Math.max(...dataPoints);
    return dataPoints.map(point => point / maxValue);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging || !currentAudioBuffer) return;

      const waveformElement = document.querySelector('.waveform-container');
      const rect = waveformElement.getBoundingClientRect();
      const position = (e.clientX - rect.left) / rect.width;
      const time = position * currentAudioBuffer.duration;

      if (dragHandle === 'start') {
        setTrimStart(Math.max(0, Math.min(time, trimEnd - 1)));
      } else {
        setTrimEnd(Math.min(currentAudioBuffer.duration, Math.max(time, trimStart + 1)));
      }
    };

    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        if (currentAudioBuffer) {
          processAudioAndUpdate(currentAudioBuffer, rawFileSize);
        }
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragHandle, currentAudioBuffer, trimStart, trimEnd]);

  useEffect(() => {
    if (currentAudioBuffer) {
      processAudioAndUpdate(currentAudioBuffer, rawFileSize);
    }
  }, [trimStart, trimEnd, quantizationBits, downsampleFactor]);

  return (
    <div className="max-w-7xl mx-auto p-6 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 min-h-screen">
      <div className="text-center mb-12 bg-gradient-to-r from-gray-800/50 to-gray-900/50 backdrop-blur-lg rounded-2xl p-8 shadow-2xl border border-gray-700/30">
        <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 mb-4">
          Sound Magic
        </h1>
        <p className="text-gray-400 text-lg">Transform your audio into Ordinal-ready art</p>
      </div>
      
      <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg rounded-2xl p-8 shadow-2xl border border-gray-700/30 mb-8">
        {currentAudioBuffer ? (
          <div className="bg-gray-800/60 rounded-xl p-6 backdrop-blur-md border border-gray-700/30">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-gray-300 text-lg font-medium">Waveform</h3>
              <span className="text-emerald-400 font-mono">
                {trimStart.toFixed(1)}s - {trimEnd.toFixed(1)}s
              </span>
            </div>
            <div className="relative h-32 waveform-container mb-6">
              <div className="absolute inset-0 flex items-end gap-px">
                {waveformData.map((point, index) => (
                  <div
                    key={index}
                    className={`flex-1 transition-all duration-200 ${
                      index >= (trimStart / currentAudioBuffer.duration) * waveformData.length &&
                      index <= (trimEnd / currentAudioBuffer.duration) * waveformData.length
                        ? 'bg-emerald-400'
                        : 'bg-gray-600'
                    }`}
                    style={{ height: `${point * 100}%` }}
                  />
                ))}
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-sm text-gray-400 mb-2">
                  <span>Start Point</span>
                  <span className="font-mono text-emerald-400">{trimStart.toFixed(1)}s</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max={Math.max(0, currentAudioBuffer?.duration - 1 || 0)}
                  step="0.1"
                  value={trimStart}
                  onChange={(e) => {
                    const newStart = Number(e.target.value);
                    if (newStart < trimEnd - 1) {
                      handleTrimChange(newStart, trimEnd);
                    }
                  }}
                  className="w-full h-3 bg-gray-700/50 rounded-xl appearance-none cursor-pointer 
                    accent-emerald-400 hover:bg-gray-600/50 transition-all
                    [&::-webkit-slider-thumb]:appearance-none
                    [&::-webkit-slider-thumb]:w-6
                    [&::-webkit-slider-thumb]:h-6
                    [&::-webkit-slider-thumb]:rounded-full
                    [&::-webkit-slider-thumb]:bg-emerald-400
                    [&::-webkit-slider-thumb]:hover:bg-emerald-300
                    [&::-webkit-slider-thumb]:transition-colors
                    [&::-webkit-slider-thumb]:hover:scale-110
                    [&::-webkit-slider-thumb]:shadow-lg
                    [&::-webkit-slider-thumb]:shadow-emerald-500/20
                    [&::-webkit-slider-thumb]:hover:shadow-emerald-400/40
                    [&::-webkit-slider-thumb]:border-4
                    [&::-webkit-slider-thumb]:border-gray-800/90"
                />
              </div>

              <div>
                <div className="flex justify-between text-sm text-gray-400 mb-2">
                  <span>End Point</span>
                  <span className="font-mono text-emerald-400">{trimEnd.toFixed(1)}s</span>
                </div>
                <input
                  type="range"
                  min={trimStart + 1}
                  max={currentAudioBuffer?.duration || 0}
                  step="0.1"
                  value={trimEnd}
                  onChange={(e) => {
                    const newEnd = Number(e.target.value);
                    if (newEnd > trimStart + 1) {
                      handleTrimChange(trimStart, newEnd);
                    }
                  }}
                  className="w-full h-3 bg-gray-700/50 rounded-xl appearance-none cursor-pointer 
                    accent-emerald-400 hover:bg-gray-600/50 transition-all
                    [&::-webkit-slider-thumb]:appearance-none
                    [&::-webkit-slider-thumb]:w-6
                    [&::-webkit-slider-thumb]:h-6
                    [&::-webkit-slider-thumb]:rounded-full
                    [&::-webkit-slider-thumb]:bg-emerald-400
                    [&::-webkit-slider-thumb]:hover:bg-emerald-300
                    [&::-webkit-slider-thumb]:transition-colors
                    [&::-webkit-slider-thumb]:hover:scale-110
                    [&::-webkit-slider-thumb]:shadow-lg
                    [&::-webkit-slider-thumb]:shadow-emerald-500/20
                    [&::-webkit-slider-thumb]:hover:shadow-emerald-400/40
                    [&::-webkit-slider-thumb]:border-4
                    [&::-webkit-slider-thumb]:border-gray-800/90"
                />
              </div>
            </div>

            <div className="mt-4 text-gray-400 text-sm flex justify-between">
              <span>Total length: {currentAudioBuffer.duration.toFixed(1)}s</span>
              <span>Selected: {(trimEnd - trimStart).toFixed(1)}s</span>
            </div>
          </div>
        ) : (
          <div className="bg-gray-800/60 rounded-xl p-6 backdrop-blur-md border border-gray-700/30">
            <div className="text-gray-400 text-center">
              Upload an audio file to view waveform
            </div>
          </div>
        )}
      </div>
      
      <div className="mb-8">
        <div className="bg-gray-800/60 rounded-xl p-8 backdrop-blur-md border border-gray-700/30 hover:border-emerald-500/30 transition-all">
          <div className="flex justify-between items-center mb-6">
            <div className="relative group">
              <span className="text-gray-300 text-xl font-medium">Bit Depth</span>
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 
                bg-black/90 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 
                transition-all duration-200 whitespace-nowrap backdrop-blur-lg">
                Higher bit rate = better quality, larger file
              </div>
            </div>
            <div className="flex items-center gap-3">
              <span className="text-emerald-400 font-mono text-2xl tabular-nums">{quantizationBits}</span>
              <span className="text-gray-400 font-medium">bits</span>
            </div>
          </div>
          
          <input
            type="range"
            min="2"
            max="8"
            value={quantizationBits}
            onChange={(e) => handleQuantizationBitsChange(Number(e.target.value))}
            className="w-full h-4 bg-gray-700/50 rounded-xl appearance-none cursor-pointer 
              accent-emerald-400 hover:bg-gray-600/50 transition-all
              [&::-webkit-slider-thumb]:appearance-none
              [&::-webkit-slider-thumb]:w-8
              [&::-webkit-slider-thumb]:h-8
              [&::-webkit-slider-thumb]:rounded-full
              [&::-webkit-slider-thumb]:bg-emerald-400
              [&::-webkit-slider-thumb]:hover:bg-emerald-300
              [&::-webkit-slider-thumb]:transition-colors
              [&::-webkit-slider-thumb]:hover:scale-110
              [&::-webkit-slider-thumb]:shadow-lg
              [&::-webkit-slider-thumb]:shadow-emerald-500/20
              [&::-webkit-slider-thumb]:hover:shadow-emerald-400/40
              [&::-webkit-slider-thumb]:border-4
              [&::-webkit-slider-thumb]:border-gray-800/90"
          />
          
          <div className="flex justify-between mt-2 text-sm text-gray-500">
            <span>Lower Quality</span>
            <span>Higher Quality</span>
          </div>
        </div>
      </div>
  
      <div className="bg-gray-800/60 rounded-xl p-6 backdrop-blur-md border border-gray-700/30 mb-8">
        <label className="flex items-center justify-center gap-4 cursor-pointer hover:bg-gray-700/50 transition-all p-6 rounded-xl border-2 border-dashed border-gray-600 group">
          <input 
            type="file" 
            accept="audio/*" 
            onChange={handleAudioUpload}
            className="hidden"
          />
          <span className="text-3xl text-emerald-400 group-hover:scale-110 transition-transform">↑</span>
          <span className="text-gray-300 text-lg font-medium">Choose Audio File</span>
        </label>
      </div>
  
      <div className="bg-gray-800/60 rounded-xl p-8 backdrop-blur-md border border-gray-700/30 hover:border-emerald-500/30 transition-all">
        <div className="flex items-center gap-6">
          <label className="relative inline-flex items-center cursor-pointer group">
            <input
              type="checkbox"
              checked={includeBackground}
              onChange={(e) => setIncludeBackground(e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-14 h-8 bg-gray-700 peer-focus:outline-none rounded-full peer 
              peer-checked:after:translate-x-6 peer-checked:bg-emerald-600/50
              after:content-[''] after:absolute after:top-[4px] after:left-[4px] 
              after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
              peer-checked:after:bg-emerald-400 hover:after:scale-95">
            </div>
            <span className="ml-4 text-gray-300 text-lg font-medium group-hover:text-emerald-400 transition-colors">
              Include Background
            </span>
          </label>
        </div>

        {includeBackground && (
          <div className="mt-6 space-y-4">
            <label className="block text-gray-400 font-medium">
              Background Inscription ID
            </label>
            <div className="relative">
              <input
                type="text"
                placeholder="Enter Inscription ID"
                value={inscriptionId}
                onChange={(e) => setInscriptionId(e.target.value)}
                className="w-full bg-gray-900/50 rounded-xl p-4 pl-12 text-gray-200 
                  placeholder-gray-500 border-2 border-gray-700/30 
                  focus:border-emerald-500/50 focus:ring-2 focus:ring-emerald-400/20 
                  transition-all outline-none"
              />
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-emerald-400">
                #
              </div>
            </div>
            <p className="text-gray-500 text-sm">
              Enter the inscription ID of the background you want to include
            </p>
          </div>
        )}
      </div>
  
      {encodedData && (
        <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg rounded-2xl p-8 shadow-2xl border border-gray-700/30">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">Preview</h3>
            <div className="text-sm bg-gray-800/60 rounded-lg px-4 py-2 backdrop-blur-sm border border-gray-700/30">
              <span className="text-gray-400">Original: {fileSize.original} KB → {fileSize.compressed} KB </span>
              <span className="ml-2 text-emerald-400">({fileSize.compressionRatio}% compressed)</span>
            </div>
          </div>
  
          <div className="mb-8">
            <div className="relative aspect-square max-w-[800px] mx-auto">
              <iframe
                srcDoc={previewHTML}
                title="Sound Magic Preview"
                className="w-full h-full bg-gray-800/60 rounded-xl border border-gray-700/30 absolute inset-0"
                sandbox="allow-scripts allow-same-origin"
              />
            </div>
          </div>
  
          <div className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <label className="block text-gray-400 mb-2 font-medium">Receiver Address</label>
                <input 
                  type="text" 
                  value={orderDetails.receiverAddress} 
                  onChange={handleReceiverAddressChange} 
                  placeholder="Enter receiver address"
                  className="w-full bg-gray-800/60 rounded-lg p-4 text-gray-200 border border-gray-700/30 focus:border-emerald-400/50 focus:ring-2 focus:ring-emerald-400/20 transition-all outline-none"
                />
              </div>
              <div>
                <label className="block text-gray-400 mb-2 font-medium">Fee Rate (sat/vB)</label>
                <input 
                  type="number" 
                  value={orderDetails.feeRate} 
                  onChange={handleFeeRateChange} 
                  min="1"
                  className="w-full bg-gray-800/60 rounded-lg p-4 text-gray-200 border border-gray-700/30 focus:border-emerald-400/50 focus:ring-2 focus:ring-emerald-400/20 transition-all outline-none"
                />
              </div>
            </div>
  
            <button 
              onClick={handleInscribe}
              disabled={showSizeWarning}
              className="w-full bg-gradient-to-r from-blue-500 to-emerald-500 hover:from-blue-600 hover:to-emerald-600 text-white px-8 py-4 rounded-xl transition-all disabled:opacity-50 disabled:cursor-not-allowed text-lg font-medium shadow-lg hover:shadow-xl disabled:hover:shadow-lg flex items-center justify-center gap-3"
            >
              <span className="text-2xl">⚡</span>
              Inscribe
            </button>
          </div>
        </div>
      )}
  
      <ConfirmPurchaseModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        paymentDetails={{
          payAddressAmount: orderDetails.totalAmount,
          payAddress: orderDetails.payAddress,
          receiverAddress: orderDetails.receiverAddress,
          feeRate: orderDetails.feeRate,
        }}
      />
      
      {errorMessage && (
        <div className="mt-6 bg-red-500/10 border border-red-500/20 rounded-xl p-4 backdrop-blur-sm">
          <div className="flex items-center gap-3 text-red-400">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="font-medium">{errorMessage}</span>
          </div>
        </div>
      )}
  </div>
);
  
};

export default SoundMagic;