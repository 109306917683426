import React, { useState, useRef, useEffect } from 'react';
import imageCompression from 'browser-image-compression';

import BatchPaymentModal from './BatchPaymentModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const compressionOptions = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    maxIteration: 15,
    exifOrientation: 1,
    initialQuality: 0.9,
    alwaysKeepResolution: false,
    fileType: 'image/webp',
    webpQuality: 0.9,
    mozjpegQuality: 0.9,
    pngQualityMin: 0.7,
    pngQualityMax: 0.9,
};

const OptimizedBatch = () => {
    const [image, setImage] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [maxSize, setMaxSize] = useState(100);
    const [processing, setProcessing] = useState(false);
    const [fileSize, setFileSize] = useState({ original: 0, compressed: 0 });
    const [compressedUrl, setCompressedUrl] = useState(null);
    const [quality, setQuality] = useState(0.9);
    const [maxWidth, setMaxWidth] = useState(1920);
    const [maxHeight, setMaxHeight] = useState(1080);
    const [colorReduction, setColorReduction] = useState(256);
    const [fullscreenPreview, setFullscreenPreview] = useState(null);
    const originalCanvasRef = useRef(null);
    const compressedCanvasRef = useRef(null);
    const fullscreenCanvasRef = useRef(null);

    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recommendedFees, setRecommendedFees] = useState({
        fastestFee: null,
        halfHourFee: null,
        hourFee: null
    });

    const [zoomLevel, setZoomLevel] = useState(1);

    const [files, setFiles] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [currentPreview, setCurrentPreview] = useState(null);
    const [progress, setProgress] = useState(0);

    const [batchUnlocked, setBatchUnlocked] = useState(false);

    const [wsConnection, setWsConnection] = useState(null);

    const [currentOrderId, setCurrentOrderId] = useState(null);

    const [previewSize, setPreviewSize] = useState('original');

    useEffect(() => {
        const ws = new WebSocket('wss://inscription-service-production.up.railway.app');
        
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.orderId === currentOrderId && 
                (data.status === 'inscribing' || data.status === 'minted')) {
                setBatchUnlocked(true);
            }
        };

        setWsConnection(ws);

        return () => {
            if (ws) ws.close();
        };
    }, [currentOrderId]);

    const handleImageUpload = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (!selectedFiles.length) return;

        setFiles(selectedFiles);
        
        // Preview first image
        const firstFile = selectedFiles[0];
        setFileSize(prev => ({ ...prev, original: firstFile.size }));
        
        const img = new Image();
        img.onload = () => {
            setImage(img);
            setupOriginalImage(img);
            processImage(img);
            setCurrentPreview(firstFile);
        };
        img.src = URL.createObjectURL(firstFile);
    };

    const processImage = async (img) => {
        setProcessing(true);
        try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d', { 
                alpha: false,
                willReadFrequently: true
            });
            
            let width = img.width;
            let height = img.height;
            
            if (width > maxWidth || height > maxHeight) {
                const ratio = Math.min(maxWidth / width, maxHeight / height);
                width = Math.floor(width * ratio);
                height = Math.floor(height * ratio);
            }

            canvas.width = width;
            canvas.height = height;
            
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(img, 0, 0, width, height);

            const imageData = ctx.getImageData(0, 0, width, height);
            const data = imageData.data;
            const factor = 256 / colorReduction;
            
            for (let i = 0; i < data.length; i += 4) {
                data[i] = Math.round(data[i] / factor) * factor;     // R
                data[i + 1] = Math.round(data[i + 1] / factor) * factor; // G
                data[i + 2] = Math.round(data[i + 2] / factor) * factor; // B
            }
            
            ctx.putImageData(imageData, 0, 0);

            const initialBlob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/webp', quality);
            });

            let compressedBlob;
            if (initialBlob.size > maxSize * 1024) {
                compressedBlob = await imageCompression(initialBlob, {
                    ...compressionOptions,
                    maxSizeMB: maxSize / 1024,
                    initialQuality: quality,
                    maxWidthOrHeight: Math.max(maxWidth, maxHeight)
                });
            } else {
                compressedBlob = initialBlob;
            }

            setFileSize(prev => ({ ...prev, compressed: compressedBlob.size }));
            const compressedImg = new Image();
            compressedImg.onload = () => {
                setCompressedImage(compressedImg);
                updateCompressedCanvas(compressedImg, width, height);
            };
            compressedImg.src = URL.createObjectURL(compressedBlob);
            setCompressedUrl(URL.createObjectURL(compressedBlob));

        } catch (error) {
            console.error('Error processing image:', error);
        } finally {
            setProcessing(false);
        }
    };

    const setupOriginalImage = (img) => {
        const width = img.width;
        const height = img.height;
        
        originalCanvasRef.current.width = width;
        originalCanvasRef.current.height = height;
        
        const originalCtx = originalCanvasRef.current.getContext('2d', {
            alpha: false,
            willReadFrequently: true
        });

        originalCtx.clearRect(0, 0, width, height);
        originalCtx.imageSmoothingEnabled = true;
        originalCtx.imageSmoothingQuality = 'high';
        originalCtx.drawImage(img, 0, 0, width, height);
    };

    const updateCompressedCanvas = (compressedImg, width, height) => {
        compressedCanvasRef.current.width = width;
        compressedCanvasRef.current.height = height;

        const compressedCtx = compressedCanvasRef.current.getContext('2d', {
            alpha: false,
            willReadFrequently: true
        });

        compressedCtx.clearRect(0, 0, width, height);
        compressedCtx.imageSmoothingEnabled = true;
        compressedCtx.imageSmoothingQuality = 'high';
        compressedCtx.drawImage(compressedImg, 0, 0, width, height);
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleDownload = async () => {
        if (compressedUrl && compressedImage) {
            try {
                const response = await fetch(compressedUrl);
                const compressedBlob = await response.blob();

                const formData = new FormData();
                formData.append("file", compressedBlob, 'compressed-image.png');

                const response2 = await fetch(`${BACKEND_URL}/upload`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'User-Selected-Fee-Rate': orderDetails.feeRate,
                        'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                    }
                });

                if (response2.ok) {
                    const result = await response2.json();
                    setCurrentOrderId(result.orderId);
                    setOrderDetails({
                        ...orderDetails,
                        totalAmount: result.payAddressAmount,
                        payAddress: result.payAddress,
                        receiverAddress: result.receiverAddress,
                        feeRate: result.feeRate
                    });
                    setIsModalOpen(true);
                } else {
                    throw new Error('Upload failed');
                }
            } catch (error) {
                console.error('Error preparing inscription:', error);
                alert('Error preparing inscription');
            }
        }
    };

    const handleConfirm = async () => {
        if (window.unisat) {
            try {
                const txid = await window.unisat.sendBitcoin(
                    orderDetails.payAddress,
                    orderDetails.totalAmount,
                    { feeRate: parseInt(orderDetails.feeRate) }
                );
                console.log('Payment successful. TXID:', txid);
                alert('Transaction successful! TXID: ' + txid);
                setIsModalOpen(false);
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePreviewClick = (type) => {
        setFullscreenPreview(type);
        setTimeout(() => {
            if (fullscreenCanvasRef.current) {
                const fullscreenCtx = fullscreenCanvasRef.current.getContext('2d', {
                    alpha: false,
                    willReadFrequently: true
                });
                
                const sourceCanvas = type === 'original' ? originalCanvasRef.current : compressedCanvasRef.current;
                
                fullscreenCanvasRef.current.width = sourceCanvas.width;
                fullscreenCanvasRef.current.height = sourceCanvas.height;
                
                fullscreenCtx.imageSmoothingEnabled = true;
                fullscreenCtx.imageSmoothingQuality = 'high';
                fullscreenCtx.drawImage(sourceCanvas, 0, 0);
            }
        }, 0);
    };

    const handleCloseFullscreen = () => {
        setFullscreenPreview(null);
        setZoomLevel(1);
        setPreviewSize('original');
    };

    const handleZoom = (e) => {
        e.preventDefault();
        const zoomFactor = 0.1; // Adjust this value to control zoom sensitivity
        const delta = -Math.sign(e.deltaY);
        
        setZoomLevel(currentZoom => {
            const newZoom = currentZoom + (delta * zoomFactor);
            // Limit zoom between 0.1 and 5
            return Math.min(Math.max(0.1, newZoom), 5);
        });
    };

    useEffect(() => {
        return () => {
            if (compressedUrl) {
                URL.revokeObjectURL(compressedUrl);
            }
        };
    }, [compressedUrl]);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await fetch('https://mempool.space/api/v1/fees/recommended');
                const fees = await response.json();
                setRecommendedFees(fees);
                setOrderDetails(prev => ({
                    ...prev,
                    feeRate: fees.halfHourFee
                }));
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchRecommendedFees();
    }, []);

    const processAllFiles = async () => {
        setProcessing(true);
        setProgress(0);
        const processed = [];
        let totalOriginalSize = 0;
        let totalCompressedSize = 0;

        for (let i = 0; i < files.length; i++) {
            try {
                const file = files[i];
                totalOriginalSize += file.size;

                const img = new Image();
                await new Promise((resolve) => {
                    img.onload = resolve;
                    img.src = URL.createObjectURL(file);
                });

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d', { 
                    alpha: false,
                    willReadFrequently: true 
                });
                
                let width = img.width;
                let height = img.height;
                
                if (width > maxWidth || height > maxHeight) {
                    const ratio = Math.min(maxWidth / width, maxHeight / height);
                    width = Math.floor(width * ratio);
                    height = Math.floor(height * ratio);
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Apply color reduction
                if (colorReduction < 256) {
                    const imageData = ctx.getImageData(0, 0, width, height);
                    const data = imageData.data;
                    const factor = 256 / colorReduction;
                    
                    for (let j = 0; j < data.length; j += 4) {
                        data[j] = Math.round(data[j] / factor) * factor;
                        data[j + 1] = Math.round(data[j + 1] / factor) * factor;
                        data[j + 2] = Math.round(data[j + 2] / factor) * factor;
                    }
                    
                    ctx.putImageData(imageData, 0, 0);
                }

                const blob = await new Promise(resolve => {
                    canvas.toBlob(resolve, 'image/webp', quality);
                });

                const compressedBlob = await imageCompression(blob, {
                    ...compressionOptions,
                    maxSizeMB: maxSize / 1024,
                    initialQuality: quality,
                    maxWidthOrHeight: Math.max(maxWidth, maxHeight)
                });

                totalCompressedSize += compressedBlob.size;
                
                processed.push({
                    originalName: file.name,
                    blob: compressedBlob,
                    originalSize: file.size,
                    compressedSize: compressedBlob.size
                });
                
                setProgress((i + 1) / files.length * 100);
                
            } catch (error) {
                console.error(`Error processing ${files[i].name}:`, error);
            }
        }

        setProcessedFiles(processed);
        setProcessing(false);
        downloadAllFiles(processed);
    };

    const downloadAllFiles = (processedFiles) => {
        processedFiles.forEach(file => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file.blob);
            link.download = `compressed_${file.originalName}`;
            link.click();
            URL.revokeObjectURL(link.href);
        });
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="p-8">
                    <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
                        Batch Image Compressor
                    </h1>
                    
                    <div className="mb-8">
                        <label className="block w-full p-8 border-2 border-dashed border-blue-300 rounded-xl text-center cursor-pointer hover:border-blue-500 transition-colors duration-200 bg-blue-50 hover:bg-blue-100">
                            <div className="space-y-2">
                                <svg className="mx-auto h-12 w-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span className="text-gray-600 text-lg">Drop your image here or click to upload</span>
                                <span className="text-sm text-gray-500">Supports: JPG, PNG, WebP</span>
                            </div>
                            <input 
                                type="file" 
                                onChange={handleImageUpload} 
                                accept="image/*" 
                                multiple
                                className="hidden" 
                            />
                        </label>
                    </div>

                    {files.length > 1 && (
                        <div className="mt-6 mb-8 bg-blue-50 border border-blue-200 rounded-xl p-6">
                            <h3 className="text-lg font-semibold text-blue-800 mb-3">
                                Batch Processing Information
                            </h3>
                            <div className="space-y-3 text-sm text-blue-700">
                                <p>
                                    <span className="font-semibold">How it works:</span>
                                </p>
                                <ol className="list-decimal ml-5 space-y-2">
                                    <li>Pay for the first inscription</li>
                                    <li>Wait for confirmation and inscription process</li>
                                    <li>Once confirmed, all remaining {files.length - 1} files will be available for batch download</li>
                                </ol>
                                <p className="mt-4 text-blue-600 font-medium">
                                    Current Status: {batchUnlocked ? 
                                        '✅ Batch Processing Unlocked' : 
                                        `🔒 Waiting for Payment - ${files.length - 1} Files Pending`}
                                </p>
                            </div>
                        </div>
                    )}

                    {files.length > 0 && (
                        <div className="mt-4">
                            <button
                                onClick={processAllFiles}
                                disabled={!batchUnlocked || processing}
                                className={`w-full py-3 px-4 rounded-lg transition-colors duration-200 
                                    ${!batchUnlocked 
                                        ? 'bg-gray-400 cursor-not-allowed' 
                                        : 'bg-blue-600 hover:bg-blue-700'} text-white`}
                            >
                                {!batchUnlocked ? (
                                    <span>Waiting for Payment - {files.length - 1} Files Pending</span>
                                ) : processing ? (
                                    <span>Processing... {Math.round(progress)}%</span>
                                ) : (
                                    <span>Process {files.length} Files</span>
                                )}
                            </button>
                        </div>
                    )}

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-6 bg-gray-50 p-6 rounded-xl">
                            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
                                <h4 className="text-sm font-semibold text-yellow-800 mb-2">
                                    ℹ️ Batch Compression Settings
                                </h4>
                                <p className="text-sm text-yellow-700">
                                    The compression settings you adjust here (quality, width, height, and color reduction) 
                                    will be applied to all images in your batch. Preview these settings on the first 
                                    image to ensure desired results.
                                </p>
                            </div>

                            <div className="space-y-4">
                                <div className="flex flex-col space-y-2">
                                    <label className="flex justify-between text-sm font-medium text-gray-700">
                                        <span>Quality</span>
                                        <span className="text-blue-600">{Math.round(quality * 100)}%</span>
                                    </label>
                                    <input 
                                        type="range" 
                                        min=".1" 
                                        max="100" 
                                        value={quality * 100} 
                                        onChange={(e) => {
                                            const newQuality = Number(e.target.value) / 100;
                                            setQuality(newQuality);
                                            if (image) processImage(image);
                                        }}
                                        onMouseUp={(e) => {
                                            if (image) processImage(image);
                                        }}
                                        className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer accent-blue-600"
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <label className="flex justify-between text-sm font-medium text-gray-700">
                                        <span>Max Width</span>
                                        <span className="text-blue-600">{maxWidth}px</span>
                                    </label>
                                    <input 
                                        type="range" 
                                        min="100" 
                                        max="3840" 
                                        step="100"
                                        value={maxWidth} 
                                        onChange={(e) => {
                                            const newWidth = Number(e.target.value);
                                            setMaxWidth(newWidth);
                                            if (image) processImage(image);
                                        }}
                                        onMouseUp={(e) => {
                                            if (image) processImage(image);
                                        }}
                                        className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer accent-blue-600"
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <label className="flex justify-between text-sm font-medium text-gray-700">
                                        <span>Max Height</span>
                                        <span className="text-blue-600">{maxHeight}px</span>
                                    </label>
                                    <input 
                                        type="range" 
                                        min="100" 
                                        max="2160" 
                                        step="100"
                                        value={maxHeight} 
                                        onChange={(e) => {
                                            const newHeight = Number(e.target.value);
                                            setMaxHeight(newHeight);
                                            if (image) processImage(image);
                                        }}
                                        onMouseUp={(e) => {
                                            if (image) processImage(image);
                                        }}
                                        className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer accent-blue-600"
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <label className="flex justify-between text-sm font-medium text-gray-700">
                                        <span>Color Reduction</span>
                                        <span className="text-blue-600">{colorReduction} colors</span>
                                    </label>
                                    <input 
                                        type="range" 
                                        min="2" 
                                        max="256" 
                                        value={colorReduction} 
                                        onChange={(e) => {
                                            const newColorReduction = Number(e.target.value);
                                            setColorReduction(newColorReduction);
                                            if (image) processImage(image);
                                        }}
                                        onMouseUp={(e) => {
                                            if (image) processImage(image);
                                        }}
                                        className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer accent-blue-600"
                                    />
                                </div>
                            </div>

                            <div className="p-4 bg-blue-50 rounded-lg space-y-2">
                                <p className="text-sm font-medium text-gray-800 flex justify-between">
                                    Original Size: <span className="text-blue-600">{(fileSize.original / 1024).toFixed(2)} KB</span>
                                </p>
                                <p className="text-sm font-medium text-gray-800 flex justify-between">
                                    Compressed Size: <span className="text-blue-600">{(fileSize.compressed / 1024).toFixed(2)} KB</span>
                                </p>
                                <p className="text-sm font-medium text-gray-800 flex justify-between">
                                    Savings: <span className="text-green-600">{fileSize.compressed ? 
                                        ((1 - fileSize.compressed / fileSize.original) * 100).toFixed(1) : 0}%</span>
                                </p>
                            </div>

                            <div className="space-y-4 mb-4">
                                <div className="flex flex-col">
                                    <label className="text-gray-700 font-semibold mb-2">Receiver Address:</label>
                                    <input 
                                        type="text" 
                                        value={orderDetails.receiverAddress} 
                                        onChange={handleReceiverAddressChange}
                                        className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Enter receiver address"
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <label className="text-gray-700 font-semibold mb-2">Fee Rate (sat/vB):</label>
                                    <input 
                                        type="number" 
                                        value={orderDetails.feeRate} 
                                        onChange={handleFeeRateChange}
                                        min="1"
                                        className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    {recommendedFees.halfHourFee && (
                                        <div className="text-sm text-gray-600 space-y-1">
                                            <p>Current Network Fees:</p>
                                            <p>Fastest: {recommendedFees.fastestFee} sat/vB</p>
                                            <p>Medium: {recommendedFees.halfHourFee} sat/vB</p>
                                            <p>Slow: {recommendedFees.hourFee} sat/vB</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {compressedUrl && (
                                <button
                                    onClick={handleDownload}
                                    className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center space-x-2"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    <span>Inscribe Image</span>
                                </button>
                            )}
                        </div>

                        <div className="grid grid-cols-1 gap-6">
                            <div className="border rounded-xl overflow-hidden shadow-sm">
                                <h3 className="text-center py-3 bg-gray-50 border-b font-medium text-gray-700">Original</h3>
                                <div className="relative h-[300px] bg-gray-100">
                                    <canvas 
                                        ref={originalCanvasRef}
                                        className="absolute top-0 left-0 w-full h-full object-contain cursor-pointer"
                                        style={{ imageRendering: 'high-quality' }}
                                        onClick={() => handlePreviewClick('original')}
                                    />
                                </div>
                            </div>
                            <div className="border rounded-xl overflow-hidden shadow-sm">
                                <h3 className="text-center py-3 bg-gray-50 border-b font-medium text-gray-700">Compressed</h3>
                                <div className="relative h-[300px] bg-gray-100">
                                    <canvas 
                                        ref={compressedCanvasRef}
                                        className="absolute top-0 left-0 w-full h-full object-contain cursor-pointer"
                                        style={{ imageRendering: 'high-quality' }}
                                        onClick={() => handlePreviewClick('compressed')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BatchPaymentModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />

            {/* Modified Fullscreen Preview Modal */}
            {fullscreenPreview && (
                <div 
                    className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center p-4"
                    onClick={handleCloseFullscreen}
                >
                    {/* Info Badge */}
                    <div className="bg-blue-900/60 text-blue-200 px-4 py-2 rounded-lg mb-3 text-sm">
                        💡 Tip: Standard Ordinal PFPs are typically displayed at 400x400
                    </div>

                    {/* Preview Size Controls */}
                    <div className="bg-gray-800 rounded-lg p-2 mb-4 z-50">
                        <div className="flex space-x-2">
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPreviewSize('original');
                                }}
                                className={`px-3 py-1 rounded ${
                                    previewSize === 'original' 
                                        ? 'bg-blue-500 text-white' 
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                }`}
                            >
                                Original
                            </button>
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPreviewSize('1000x1000');
                                }}
                                className={`px-3 py-1 rounded ${
                                    previewSize === '1000x1000' 
                                        ? 'bg-blue-500 text-white' 
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                }`}
                            >
                                1000x1000
                            </button>
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPreviewSize('400x400');
                                }}
                                className={`px-3 py-1 rounded ${
                                    previewSize === '400x400' 
                                        ? 'bg-blue-500 text-white border border-blue-300' 
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                }`}
                            >
                                400x400 (PFP)
                            </button>
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPreviewSize('200x200');
                                }}
                                className={`px-3 py-1 rounded ${
                                    previewSize === '200x200' 
                                        ? 'bg-blue-500 text-white' 
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                }`}
                            >
                                200x200
                            </button>
                        </div>
                    </div>

                    <div 
                        className="relative max-w-full max-h-full overflow-hidden"
                        onClick={e => e.stopPropagation()}
                    >
                        <button 
                            onClick={handleCloseFullscreen}
                            className="absolute top-4 right-4 text-white bg-gray-800 rounded-full p-2 hover:bg-gray-700 z-50"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div 
                            className="transform-origin-center select-none"
                            onWheel={handleZoom}
                            style={{
                                transform: `scale(${zoomLevel})`,
                                transition: 'transform 0.1s ease-out'
                            }}
                        >
                            <div className="relative">
                                <canvas 
                                    ref={fullscreenCanvasRef}
                                    className="max-w-full max-h-[90vh] object-contain"
                                    style={{ 
                                        imageRendering: 'high-quality',
                                        width: previewSize === 'original' ? 'auto' : 
                                              previewSize === '1000x1000' ? '1000px' :
                                              previewSize === '400x400' ? '400px' : '200px',
                                        height: previewSize === 'original' ? 'auto' : 
                                               previewSize === '1000x1000' ? '1000px' :
                                               previewSize === '400x400' ? '400px' : '200px',
                                    }}
                                />
                                <div className="absolute bottom-4 left-4 bg-black bg-opacity-75 text-white px-3 py-1 rounded">
                                    {previewSize === 'original' 
                                        ? `Original (${fullscreenCanvasRef.current?.width || 0}x${fullscreenCanvasRef.current?.height || 0})`
                                        : `Preview at ${previewSize}`
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {batchUnlocked && files.length > 1 && (
                <div className="mt-4 p-4 bg-black/40 rounded-xl border border-blue-500/10">
                    <h3 className="text-lg font-semibold text-blue-200 mb-2">
                        Batch Ready
                    </h3>
                    <button
                        onClick={() => downloadAllFiles(processedFiles)}
                        className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg 
                                 hover:bg-blue-700 transition-colors duration-200"
                    >
                        Download {files.length - 1} Remaining Files
                    </button>
                </div>
            )}
        </div>
    );
};

export default OptimizedBatch;

