import React from 'react';

const SpriteConverter = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      fontSize: '2rem', 
      fontWeight: 'bold' 
    }}>
      Coming Soon
    </div>
  );
};

export default SpriteConverter;
