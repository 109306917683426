import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const BatchPaymentModal = ({ isOpen, onConfirm, onCancel, paymentDetails }) => {
    const [btcPriceInUsd, setBtcPriceInUsd] = useState(null);
    const [copiedField, setCopiedField] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('Waiting for Payment...');
    const [transactionId, setTransactionId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

    useEffect(() => {
        if (isOpen) {
            // Reset state when modal is opened
            setPaymentStatus('Waiting for Payment...');
            setTransactionId(null);
            setTimeLeft(15 * 60);

            fetchBtcPrice();

            const ws = new WebSocket('wss://inscription-service-production.up.railway.app');

            ws.onopen = () => {
                console.log('WebSocket connection established');
                ws.send(JSON.stringify({ type: 'subscribe', orderId: paymentDetails.orderId }));
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.orderId === paymentDetails.orderId) {
                    console.log('Received WebSocket message:', data);
                    
                    switch (data.status) {
                        case 'inscribing':
                            setPaymentStatus('Success! Inscribing your Ordinals...');
                            break;
                        case 'minted':
                            setPaymentStatus('Success! Your Ordinals are Minted!');
                            break;
                        case 'paid':
                            setPaymentStatus('Payment Received! Waiting for inscription...');
                            break;
                        case 'pending':
                            setPaymentStatus('Waiting for Payment...');
                            break;
                        case 'expired':
                            setPaymentStatus('Payment Window Expired');
                            setTimeout(() => onCancel(), 3000);
                            break;
                        default:
                            setPaymentStatus(data.status);
                    }
                    
                    if (data.transactionId) {
                        setTransactionId(data.transactionId);
                    }
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };

            const timerInterval = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 0 && paymentStatus !== 'inscribing' && paymentStatus !== 'minted') {
                        onCancel();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => {
                ws.close();
                clearInterval(timerInterval);
            };
        }
    }, [isOpen, paymentDetails.orderId, onCancel]);

    const fetchBtcPrice = async () => {
        try {
            const response = await fetch('https://mempool.space/api/v1/prices');
            const data = await response.json();
            setBtcPriceInUsd(data.USD);
        } catch (error) {
            console.error('Error fetching BTC price:', error);
        }
    };

    const convertSatoshiToUsd = (satoshi) => {
        if (!btcPriceInUsd) return '0.00';
        return ((satoshi / 100000000) * btcPriceInUsd).toFixed(2);
    };

    const copyToClipboard = (text, field) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedField(field);
                setTimeout(() => setCopiedField(null), 2000);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black/90 backdrop-blur-md flex items-start justify-center z-50 p-4 overflow-y-auto">
            <div className="relative bg-gradient-to-b from-gray-900 via-blue-900/20 to-gray-900 rounded-3xl max-w-2xl w-full 
                         overflow-hidden shadow-[0_0_100px_0_rgba(59,130,246,0.2)] border border-blue-500/20 my-8">
                {/* Modal Header */}
                <div className="sticky top-0 z-10 px-8 py-5 bg-black/40 border-b border-blue-500/20">
                    <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-500">
                        Confirm Batch Inscription
                    </h2>
                </div>

                {/* Modal Content */}
                <div className="p-8 space-y-6">
                    {/* Total Cost */}
                    <div className="bg-black/40 rounded-2xl p-6 border border-blue-500/10">
                        <div className="flex justify-between items-center">
                            <span className="text-blue-200">Total Cost ({paymentDetails.fileCount} files)</span>
                            <div className="text-right">
                                <div className="text-xl font-bold text-white">
                                    {(paymentDetails.payAddressAmount / 100000000).toFixed(8)} BTC
                                </div>
                                <div className="text-sm text-blue-200">
                                    ${convertSatoshiToUsd(paymentDetails.payAddressAmount)} USD
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payment Status */}
                    <div className={`bg-black/40 rounded-2xl p-6 border ${
                        paymentStatus === 'Payment Window Expired'
                            ? 'border-red-500/50' 
                            : 'border-blue-500/10'
                    }`}>
                        <div className="flex justify-between items-center">
                            <span className="text-blue-200">Payment Status</span>
                            <div className="text-right">
                                <div className={`text-xl font-bold ${
                                    paymentStatus === 'Payment Window Expired'
                                        ? 'text-red-400'
                                        : 'text-white'
                                }`}>
                                    {paymentStatus}
                                </div>
                                {transactionId && (paymentStatus === 'inscribing' || paymentStatus === 'minted') && (
                                    <div className="text-sm text-blue-200">
                                        <a 
                                            href={`https://mempool.space/tx/${transactionId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="hover:text-blue-400 transition-colors"
                                        >
                                            TX: {transactionId.slice(0, 8)}...{transactionId.slice(-8)}
                                        </a>
                                    </div>
                                )}
                                {paymentStatus === 'Payment Window Expired' && (
                                    <div className="text-sm text-red-400 mt-2">
                                        Payment window expired. Please start a new purchase request.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Pay Address */}
                    <div className="space-y-3">
                        <label className="block text-sm font-medium text-blue-200">Pay Address</label>
                        <div className="bg-black/40 rounded-xl p-4 border border-blue-500/10">
                            <div className="font-mono text-blue-100 text-sm break-all mb-3">
                                {paymentDetails.payAddress}
                            </div>
                            <button
                                onClick={() => copyToClipboard(paymentDetails.payAddress, 'address')}
                                className="w-full py-3 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 
                                         rounded-lg text-white text-sm font-medium transition-all
                                         hover:shadow-lg hover:shadow-blue-500/25"
                            >
                                {copiedField === 'address' ? 'Copied!' : 'Copy'}
                            </button>
                        </div>
                    </div>

                    {/* Amount to Send */}
                    <div className="space-y-3">
                        <label className="block text-sm font-medium text-blue-200">Amount to Send</label>
                        <div className="bg-black/40 rounded-xl p-4 border border-blue-500/10">
                            <div className="font-mono text-blue-100 text-sm mb-3">
                                {(paymentDetails.payAddressAmount / 100000000).toFixed(8)} BTC
                            </div>
                            <button
                                onClick={() => copyToClipboard((paymentDetails.payAddressAmount / 100000000).toFixed(8), 'amount')}
                                className="w-full py-3 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 
                                         rounded-lg text-white text-sm font-medium transition-all
                                         hover:shadow-lg hover:shadow-blue-500/25"
                            >
                                {copiedField === 'amount' ? 'Copied!' : 'Copy'}
                            </button>
                        </div>
                    </div>

                    {/* QR Code */}
                    <div className="flex justify-center p-6 bg-black/20 rounded-2xl border border-blue-500/10 relative overflow-hidden">
                        <div className="bg-white p-4 rounded-xl relative z-10">
                            <QRCodeSVG 
                                value={`bitcoin:${paymentDetails.payAddress}?amount=${(paymentDetails.payAddressAmount / 100000000).toFixed(8)}`}
                                size={200}
                                level="H"
                            />
                        </div>
                    </div>

                    {/* Additional Details */}
                    <div className="grid grid-cols-2 gap-4 bg-black/20 rounded-xl p-4 border border-blue-500/10">
                        <div>
                            <span className="text-sm text-blue-200">Receiver Address</span>
                            <p className="font-medium text-white truncate" title={paymentDetails.receiverAddress}>
                                {paymentDetails.receiverAddress}
                            </p>
                        </div>
                        <div>
                            <span className="text-sm text-blue-200">Fee Rate</span>
                            <p className="font-medium text-white">{paymentDetails.feeRate} sat/vB</p>
                        </div>
                    </div>

                    {/* Instructions */}
                    <div className="space-y-2 text-sm text-blue-200/80">
                        <p>1. Send the exact amount to the address above</p>
                        <p>2. Wait for network confirmation</p>
                        <p>3. Your batch of {paymentDetails.fileCount} Ordinals will be delivered to your wallet</p>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="sticky bottom-0 px-8 py-6 bg-black/40 border-t border-blue-500/20">
                    <div className="flex justify-center">
                        <button
                            onClick={onCancel}
                            className="w-1/2 py-4 bg-black/40 hover:bg-black/60 text-white rounded-xl
                                     text-lg font-semibold transition-colors border border-blue-500/20"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchPaymentModal;