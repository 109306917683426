import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Exhibition from './components/Exhibition';
import InscriptionForm from './components/InscriptionForm';
import BalanceDisplay from './components/BalanceDisplay';
import TransactionHistory from './components/TransactionHistory';
import P5jsCodeCompressMagic from './components/P5jsCodeCompressMagic';
import InscriptionBlock from './components/InscriptionBlock';
import PMT from './components/PMT';
import InscribePad from './components/InscribePad';
import VideoCompressMagic from './components/VideoCompressMagic';
import ImageCompressMagic from './components/ImageCompressMagic';
import OrderDashboard from './components/OrderDashboard';
import HTMLCompressMagic from './components/HTMLCompressMagic.js';
import SoundMagic from './components/SoundMagic'; // Import the SoundMagic component
import SpriteConverter from './components/SpriteConverter'; // Add this import
import OptimizedImageCompressor from './components/OptimizedImageCompressor';
import OptimizedBatch from './components/OptimizedBatch';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/exhibition" element={<Exhibition />} />
          <Route path="/inscribe" element={<InscriptionForm />} />
          <Route path="/balance" element={<BalanceDisplay />} />
          <Route path="/transaction-history" element={<TransactionHistory />} />
          <Route path="/p5-magic" element={<P5jsCodeCompressMagic />} />
          <Route path="/what-are-people-buying?" element={<InscriptionBlock />} />
          <Route path="/mint" element={<PMT />} />
          <Route path="/inscribe-pad" element={<InscribePad />} />
          <Route path="/Video-Compress-Magic" element={<VideoCompressMagic />} />
          <Route path="/Image-Compress-Magic" element={<ImageCompressMagic />} />
          <Route path="/orders" element={<OrderDashboard />} />
          <Route path="/html-Compress-Magic" element={<HTMLCompressMagic />} />
          <Route path="/sound-magic" element={<SoundMagic />} /> {/* New Route for SoundMagic */}
          <Route path="/sprite-converter" element={<SpriteConverter />} /> {/* Add this route */}
          <Route path="/optimized-compressor" element={<OptimizedImageCompressor />} />
          <Route path="/optimized-batch" element={<OptimizedBatch />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
